import {createSlice} from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: "user",
    initialState:{
        currentUser: null,
        isFetching: false,
        error: false
    },
    reducers: {
        loginStart: (state)=>{   
            state.isFetching= true;
        },
        loginSuccess: (state,action)=>{
            state.currentUser= action.payload;
            state.isFetching= false;
        },
        loginFailure: (state)=>{
            state.isFetching= false;
            state.error= true;
        },
        logout: (state) => {
            // Reset the state to its initial state.
            state.currentUser= null;
            state.isFetching= false;
            state.error= false;
        },
    },

});

export const {loginStart, loginSuccess, loginFailure, logout} = userSlice.actions
export default userSlice.reducer;