import { Add, Remove } from '@mui/icons-material'
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile } from '../responsive';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StripeCheckout from "react-stripe-checkout"
import { useNavigate } from 'react-router-dom';

const KEY = process.env.REACT_APP_STRIPE;

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
  
`;
const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
  ${mobile({   
        display: "none"
    })}
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({   
        flexWrap: "wrap"
    })}
`;

const Info = styled.div`
  flex: 3;
  //background-color: red;
  
  ${mobile({   
        maxWidth: "90vw",
        
    })}
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({   
        flexDirection: "column",
        marginBottom: "20px"
    })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //background-color: black;
  //width: 30%;
  
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: ${(props) => props.color};
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
  ${mobile({   
        margin: "5px 20px"
    })}
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobile({   
        border: "1px solid teal",
        padding: "5px"
    })}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 50vh;
  ${mobile({   
        height: "40vh"
    })}
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
`;

const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
  &:disabled{
    background-color: gray;
  }
`;

const Warning = styled.span`
  color: red;
  font-size: 15px;
`

const Cart = () => {
  const cart = useSelector(state=>state.cart);
  const [stripeToken,setStripeToken] =useState(null);
  const onToken = (token) => {
    setStripeToken(token);
  }
  //console.log(stripeToken)
  const navigate = useNavigate();
  const shoppingButton = () => {
    navigate("/");
  }

  let isDisabled = true;
  const user = useSelector((state) => state.user.currentUser );
  user ? isDisabled=false:isDisabled = true;

  useEffect(() => {
    window. scrollTo(0, 0);
},[])
  return (
    <Container>
      <Navbar />
      <Announcement />
      <Wrapper>
        <Title>YOUR BAG</Title>
        <Top>
          <TopButton onClick={shoppingButton}>CONTINUE SHOPPING</TopButton>
          <TopTexts>
            <TopText>Shopping Bag({cart.quantity})</TopText>
            <TopText>Your Wishlist (0)</TopText>
          </TopTexts>
          <TopButton type="filled">CHECKOUT NOW</TopButton>
        </Top>
        <Bottom>
          <Info>
            {cart.products.map((product) => <Product>
              <ProductDetail>
                <Image src={product.img} />
                <Details>
                  <ProductName>
                    <b>Product:</b> {product.title}
                  </ProductName>
                  {/* <ProductId>
                    <b>ID:</b> {product._id}
                  </ProductId> */}
                  <ProductColor color={product.color} />
                  <ProductSize>
                    <b>Size:</b> {product.size}
                  </ProductSize>
                </Details>
              </ProductDetail>
              <PriceDetail>
                <ProductAmountContainer>
                  <Add />
                  <ProductAmount>{product.quantity}</ProductAmount>
                  <Remove />
                </ProductAmountContainer>
                <ProductPrice>₹ {product.price*product.quantity}</ProductPrice>
              </PriceDetail>
            </Product>)}
            <Hr />
            
          </Info>
          <Summary>
            <SummaryTitle>ORDER SUMMARY</SummaryTitle>
            <SummaryItem>
              <SummaryItemText>Subtotal</SummaryItemText>
              <SummaryItemPrice>₹ {cart.total}</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText>Estimated Shipping</SummaryItemText>
              <SummaryItemPrice>₹ 50</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText>Shipping Discount</SummaryItemText>
              <SummaryItemPrice>₹ -50</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem type="total">
              <SummaryItemText>Total</SummaryItemText>
              <SummaryItemPrice>₹ {cart.total}</SummaryItemPrice>
            </SummaryItem>
            <StripeCheckout
            name='Shoppy'
            image='https://images.pexels.com/photos/6069556/pexels-photo-6069556.jpeg?auto=compress&cs=tinysrgb&w=600'
            billingAddress
            shippingAddress
            description={`Your total is ₹${cart.total}`}
            amount={cart.total*100}
            token={onToken}
            stripeKey={KEY}
            >
              <Button disabled={isDisabled}>CHECKOUT NOW</Button>
              {!user && <Warning>Please Log In first</Warning>}
            </StripeCheckout> 
          </Summary>
        </Bottom>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Cart;